import { ProjectCategory } from '~/enums';

import optionDriverMain from './assets/optiondriver.jpg';
import optionDriverMainMobile from './assets/optiondriver-mobile.jpg';
import optionDriver1 from './assets/optiondriver-1.jpg';
import optionDriver1Mobile from './assets/optiondriver-1-mobile.jpg';
import optionDriver2 from './assets/optiondriver-2.jpg';
import optionDriver2Mobile from './assets/optiondriver-2-mobile.jpg';
import avatar from './assets/avatar.jpg';
import routes from '~/routes';

const optionGeneralInfo = {
  name: 'Option Driver',
  nameShort: 'Option Driver',
  category: ProjectCategory.HUMAN_RESOURCES,
  summary: 'Compensation design leader',
  img: optionDriverMain,
  imgMobile: optionDriverMainMobile,
  projectPageUrl: routes.CASE_STUDIES_OPTION_DRIVER,
  description: '',
};

const optionDriverCaseStudy = {
  generalInfo: optionGeneralInfo,
  about: {
    descriptionShort:
      'Dee DiPietro is a Silicon Valley legend. She  basically coined the phrase “compensation design” and changed the hiring habits and standards of the whole tech industry.',
    descriptionLong:
      'After dozens of years working as a trusted consultant and founding Advanced HR, Dee decided to put her experience and knowledge into an automated product. With a bold vision for Option Driver, she came to SwingDev.',
    types: ['Design', 'Development'],
    techStack: ['Angular', 'Node'],
    image: {
      img: optionDriver1,
      imgMobile: optionDriver1Mobile,
    },
  },
  client: {
    name: 'Dee DiPietro',
    position: 'Founder & CEO',
    company: 'Advanced-HR',
    img: avatar,
    quote:
      'Our Experience with SwingDev has been wonderful. They are a part of our staff and we would not enjoy our success without them.',
  },
  platform: {
    label: 'Platform',
    types: ['Web'],
  },
  scope: {
    descriptionShort:
      'The prior work of Advanced HR relied partly on raw data, but it owed its success to the industry expertise of Dee DiPietro, who has been gaining experience since the seventies, and understood that numbers don’t always tell the whole story.',
    descriptionLong:
      '<p>Our main challenge was turning her knowledge and so-called gut into <b>deterministic algorithms</b> and <b>mixing it with data</b>. The solution needed to contain the consultant’s “human touch” to match the precision of DiPietro’s equity benchmarking and planning. Otherwise, her clients wouldn’t trust the newly developed tool.</p><p>At the same time, we were in a hurry to launch OptionDriver quickly as an MVP. The first version released on schedule and <b>turned out to be a success</b>. Both sides decided to proceed with the partnership, and enhance Option Driver with new features. SwingDev assigned a full team to this project, consisting of developers, designers, a product manager, and a senior software architect.</p><p>In 2018, Advanced HR, with OptionDriver as its flagship product, got acquired by Solium for an undisclosed, multi-million dollar amount. In 2019, Solium got to the hands of Morgan Stanley. SwingDev still remains the only team standing behind OptionDriver’s tech.</p>',
    image: {
      img: optionDriver2,
      imgMobile: optionDriver2Mobile,
    },
  },
  tldr:
    'We fully programmed and designed the most popular compensation design product on the market, used by hundreds of companies and VCs in Silicon Valley and beyond. After two acquisitions, we are still responsible for its further development and maintenance.',
};

export default optionDriverCaseStudy;
